import React, {useContext, useEffect} from 'react';
import {useQuery} from "react-query";
import confetti from "canvas-confetti";
import apiClient from "../../utils/apiClient";
import {AppContext} from "../../contexts/AppContext";
import {GameContext} from "../../contexts/GameContext";
import {ERROR, GAME, MULTIPLIER, START} from "../../config/constants";
import Button from "../elements/Button/Button";
import PriceMenu from "../ActionBoard/components/PriceMenu/PriceMenu";
import MultiplierButton from "../ActionBoard/components/MultiplierButton/MultiplierButton";
import GoldNumberButton from "../ActionBoard/components/GoldNumberButton/GoldNumberButton";
import {useTranslation} from "react-i18next";
import PaymentSuccess from "../ActionBoard/components/PaymentSuccess/PaymentSuccess";
import Utils from "../../utils/Utils";
import styles from "./EndGameBoard.module.scss";
import SoundManager from "../../utils/SoundManager";

const EndGameActionBoard = () => {
    const {t} = useTranslation();
    const {
        setUserPaymentSuccess,
        userPaymentSuccess,
        tokenData,
        setCurrentModal,
        refetchCustomerBalance
    } = useContext(AppContext);
    const {
        buyTicket,
        currentTicket,
        setCurrentTicket,
        setUnfinishedTicket,
        activePrice,
        setActivePrice,
        setGameState,
        setWinningDraw,
        setPicked,
        setIsMultiplierUsed,
        isMultiplierUsed,
        setGoldNumber,
        setDisabled,
        setMultiplier,
        multiplier,
        goldNumber,
        picked,
        setIsResultsShown,
        setIsGoldNumberUsed,
        setSpeedMultiplier,
        setShowSkipDrawing,
        gamePrice,
        isDemo,
        demoBalance,
        setDemoBalance,
        demoTicket,
        buyDemoGame,
        setIsDrawSkipped,
    } = useContext(GameContext);

    useQuery(
        'finalize',
        () =>
            apiClient.put(`${currentTicket.finalizeUrl}`, {
                status: 'Finalized',
            }),
        {
            enabled: !!currentTicket && !!tokenData?.access_token,
            refetchOnWindowFocus: false,
            onError: () => {
                setCurrentModal(ERROR);
            },
        }
    );

    const handlePlayAgain = async () => {
        setIsResultsShown(false);
        setCurrentTicket({});
        setUnfinishedTicket(null);
        setWinningDraw([]);
        setMultiplier(null);
        setIsMultiplierUsed(isMultiplierUsed);
        setGoldNumber(goldNumber);
        setSpeedMultiplier(1);
        setPicked(picked);
        setShowSkipDrawing(false);
        setIsDrawSkipped(false);
        if (!!tokenData?.access_token) {
            setDisabled(true);
            const result = await buyTicket();
            if (result !== null) {
                setUserPaymentSuccess(true);
                await Utils.timeout(3000);
                setUserPaymentSuccess(false);
                if (isMultiplierUsed) {
                    setGameState(MULTIPLIER);
                } else {
                    setGameState(GAME);
                }
            }
        } else {
            if (demoBalance != null) {
                setDemoBalance(demoBalance - gamePrice);
            }
            setDisabled(true);
            const result = await buyDemoGame();
            if (result !== null) {
                setUserPaymentSuccess(true);
                await Utils.timeout(3000);
                setUserPaymentSuccess(false);
                if (isMultiplierUsed) {
                    setGameState(MULTIPLIER);
                } else {
                    setGameState(GAME);
                }
            }
        }
    };

    const handleBackToStart = () => {
        setIsResultsShown(false);
        setCurrentTicket({});
        setUnfinishedTicket(null);
        setWinningDraw([]);
        setMultiplier(null);
        setIsMultiplierUsed(false);
        setIsGoldNumberUsed(false);
        setGoldNumber(0);
        setPicked([]);
        setGameState(START);
        setUserPaymentSuccess(false);
        setSpeedMultiplier(1);
        setShowSkipDrawing(false);
        setIsDrawSkipped(false);
    };


    useEffect(() => {
        if (!!tokenData?.access_token) {
            refetchCustomerBalance();
        } else {
            if (isDemo && demoBalance != null) {
                setDemoBalance(demoBalance + demoTicket.prizeValue);
            }
        }
    }, []);

    function startConfetti() {
        const canvas = document.getElementById("confetti-canvas");
        // @ts-ignore
        const myConfetti = confetti.create(canvas, {
            resize: true,
            useWorker: true
        });
        const end = Date.now() + 3 * 1000;
        const colors = ["#fcf299", "#f3bc56", "#d9d9d9"];
        if (!myConfetti) {
            return;
        }
        (function frame() {
            myConfetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: 0 },
                colors: colors,
            });

            myConfetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: 1 },
                colors: colors,
            });

            if (Date.now() < end) {
                requestAnimationFrame(frame);
            }
        })();
    }

    useEffect(() => {
        SoundManager.instance.playVO('popup_results');
        if (currentTicket.prizeValue && currentTicket.prizeValue > 0) {
            startConfetti();
            SoundManager.instance.playVO('confetti');
        } else if (demoTicket.prizeValue && demoTicket.prizeValue > 0) {
            startConfetti();
            SoundManager.instance.playVO('confetti');
        }
    }, []);

    return (
        <div className={styles.settings}>
            {userPaymentSuccess && <PaymentSuccess/>}
            <div className={styles.actionBoard}>
                <PriceMenu activePrice={activePrice} setActivePrice={setActivePrice}/>
                <MultiplierButton/>
                <GoldNumberButton/>
            </div>
            <div className={styles.bottomBlock}>
                <Button className={styles.playButton} isDisabled={picked.length === 0} onClick={handlePlayAgain}>
                    <p className={styles.playButtonText}> {t('roundEnd.playAgain', {price: Utils.formatCurrency(gamePrice)})} </p>
                </Button>
                <Button isSecondary className={styles.backToStartButton} onClick={handleBackToStart}>
                    {t('roundEnd.backToStart')}
                </Button>
            </div>
        </div>
    );
};

export default EndGameActionBoard;
