import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Utils from "../../../../utils/Utils";
import styles from './ProgressTable.module.scss';
import {GameContext} from "../../../../contexts/GameContext";
import clsx from "clsx";

interface Prizes {
    correctNumbers: number;
    prizeFactor: number;
    specialPrizeFactor: number;
}

interface PrizePlanState {
    chosenNumbersCount: number;
    isSpecialPrize: boolean;
    prizes: Prizes[];
}

const ProgressTable = () => {
    const {t} = useTranslation();
    const [selectedPrizePlan, setSelectedPrizePlan] = useState<PrizePlanState>();
    const {
        prizePlans,
        picked,
        isGoldNumberUsed,
        activePrice,
        multiplier,
        bouncedBallNumbers,
        winningDraw,
        currentTicket
    } = useContext(GameContext);

    const processWinningBalls = () => {
        const winningBalls = winningDraw.filter((num) => picked.includes(num));
        return winningBalls.filter((num) => bouncedBallNumbers.includes(num));
    };

    const tableHeaders = [
        {tableHeader: t('prizes.hits')},
        {tableHeader: t('header.prize')},
    ]

    useEffect(() => {
        let plan = prizePlans.find(plan =>
            plan.chosenNumbersCount === picked.length &&
            !plan.isSpecialPrize,
        );

        if (!plan) {
            plan = prizePlans.reduce((maxPlan, currentPlan) =>
                currentPlan.chosenNumbersCount > (maxPlan?.chosenNumbersCount || 0) ? currentPlan : maxPlan, null);
        }

        setSelectedPrizePlan(plan || null);
    }, [prizePlans, picked.length, isGoldNumberUsed]);

    useEffect(() => {
        processWinningBalls();
    }, [bouncedBallNumbers]);

    useEffect(() => {
        console.log('multiplier', multiplier);
    }, [multiplier]);

    return (
        <div className={styles.prizesBox}>
            {currentTicket && currentTicket.boughtMultiplier && multiplier && multiplier > 1 && (
                <div className={styles.multiplierBox}>
                    <h3 className={styles.multiplierHeader}>{t('header.activeMultiplier')}</h3>
                    <p className={styles.progressText}>{`X${multiplier}`}</p>
                </div>
            )}
            <div className={styles.tableBox}>
                <table className={styles.table}>
                    <thead>
                    <tr className={styles.row}>
                        {tableHeaders &&
                            tableHeaders.map(({tableHeader}) => {
                                return (
                                    <th key={tableHeader} className={styles.tableHeader}>
                                        {tableHeader}
                                    </th>
                                );
                            })}
                    </tr>
                    </thead>
                    <tbody>
                    {selectedPrizePlan && selectedPrizePlan.prizes.slice().reverse().map(({
                                                                                              correctNumbers,
                                                                                              prizeFactor
                                                                                          }) => (
                        <tr className={clsx(styles.progressRow, {
                            [styles.activeRow]: processWinningBalls().length === correctNumbers,
                            [styles.goldRow]: isGoldNumberUsed,
                            [styles.activeGoldRow]: isGoldNumberUsed && processWinningBalls().length === correctNumbers,
                        })} key={correctNumbers}>
                            <td className={styles.hitsData}>
                                {correctNumbers}
                            </td>
                            <td className={styles.prizesData}>
                                {prizeFactor === 0 ? '-' : Utils.formatCurrency(prizeFactor * activePrice)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProgressTable;
