import React, {useContext, useEffect, useRef, useState} from 'react';
import gsap from 'gsap';
import styles from './Countdown.module.scss'
import {GameContext} from "../../contexts/GameContext";
import SoundManager from "../../utils/SoundManager";

interface CountdownProps {
    duration: number;
    onComplete: () => void;
}

const Countdown = ({duration = 3, onComplete}: CountdownProps) => {
    const [count, setCount] = useState(duration);
    const {setShowSkipDrawing} = useContext(GameContext);
    const countdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (count === 0) {
            onComplete();
            setShowSkipDrawing(true);
            return;
        }
        const countdownAnimation = gsap.fromTo(countdownRef.current,
            {opacity: 0, scale: 0.5},
            {opacity: 1, scale: 1, duration: 0.5, ease: 'power1.inOut'}
        );

        const interval = setInterval(() => {
            setCount(prevCount => prevCount - 1);
        }, 1000);

        return () => {
            clearInterval(interval);
            countdownAnimation.kill();
        };
    }, [count]);

    useEffect(() => {
        SoundManager.instance.playVO('countdown');
    }, []);

    return (
        <div ref={countdownRef} className={styles.coundownContainer}>
            {count > 0 &&
                <div className={styles.countdownText}>
                    <h1>{count}</h1>
                </div>
            }
        </div>
    );
};

export default Countdown;
