import React, {
    Dispatch,
    SetStateAction, useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {AppContext} from "../../contexts/AppContext";
import Button from "../elements/Button/Button";
import {
    ERROR, GAME,
    LOGIN, MULTIPLIER,
    SESSION_TIMEOUT,
    UNFINISHED,
    WALLET
} from "../../config/constants";
import styles from './SystemPopUp.module.scss';
import SoundManager from "../../utils/SoundManager";
import {GameContext} from "../../contexts/GameContext";

type Props = {
    modalType: string;
};

type ModalContent = {
    title: string;
    textContent: string;
    buttonOneText: string;
    buttonOneFunction: () => void | null;
    isButtonOneDisabled?: boolean;
    buttonTwoText: string;
    buttonTwoFunction: () => void | null;
};

const SystemPopUp = ({modalType}: Props) => {
    const {t} = useTranslation();
    const {setCurrentModal, callback, currentError} = useContext(AppContext);
    const {isMultiplierUsed, setGameState, setIsDemo} = useContext(GameContext);

    const modalWrapperRef: React.Ref<HTMLDivElement> = useRef(null);
    const modalRef: React.Ref<HTMLDivElement> = useRef(null);
    const [modalContent, setModalContent]: [
            ModalContent | null | undefined,
        Dispatch<SetStateAction<ModalContent | null | undefined>>
    ] = useState();

    const closeModal: () => void = () => {
        setCurrentModal('');
    };

    const startDemo: () => void = () => {
        closeModal();
        setIsDemo(true);
    }

    const closeClient: () => void = () => {
        callback('close', '');
    };

    const startGame: () => void = () => {
        if (isMultiplierUsed) {
            setGameState(MULTIPLIER);
        } else {
            setGameState(GAME);
        }
        closeModal();
    }

    const setContent: (modalType: string) => ModalContent | null = (
            modalType: string
        ) => {
            let content: ModalContent | null = null;
            let errorMessage: string = t('system.generalErrorTitle');

            if (currentError) {
                switch (currentError) {
                    case 'Error_CouldNotFindCompetitionForId':
                    case 'Error_Invalid_Competition_For_Ticket':
                        errorMessage = 'No active competition found.';
                        break;
                    case 'Error_CantAnswerThisQuestion':
                        errorMessage = 'Could not answer this question.';
                        break;
                    case 'Ticket_Is_Already_Finalized':
                        errorMessage = 'This ticket has already been finished.';
                        break;
                    case 'Error_TicketNotFound':
                    case 'Error_EntityIdMustBeGreaterThanZero':
                    case 'Error_WrongCompetitionId':
                    case 'Error_NoQuestionsFound':
                        errorMessage = 'Unknown error when fetching your ticket.';
                        break;
                    case 'Error_TicketNotActive':
                        errorMessage = 'This ticket is not active anymore.';
                        break;
                    case 'Purchase_Failed':
                    case 'Invalid_Order_Reference':
                    case 'UnSuccessful_Transaction':
                        errorMessage = 'Wager failed. Please try again.';
                        break;
                    default:
                        errorMessage = t('system.generalErrorContent');
                }
            }

            switch (modalType) {
                case LOGIN:
                    content = {
                        title: t('system.loginTitle'),
                        textContent: t('system.loginContent'),
                        buttonOneText: t('system.login'),
                        buttonOneFunction: (): void => {
                            closeModal();
                        },
                        buttonTwoText: t('system.demo'),
                        buttonTwoFunction: (): void => {
                            startDemo();
                        },
                    };
                    break;

                case WALLET:
                    content = {
                        title: t('system.walletTitle'),
                        textContent: t('system.walletContent'),
                        buttonOneText: t('ok'),
                        buttonOneFunction: (): void => {
                            closeModal();
                        },
                        buttonTwoText: '',
                        buttonTwoFunction: () => null,
                    };
                    break;

                case SESSION_TIMEOUT:
                    content = {
                        title: t('system.sessionTimeoutTitle'),
                        textContent: t('system.sessionTimeoutContent'),
                        buttonOneText: t('system.sessionTimeoutButton'),
                        buttonOneFunction: (): void => {
                            closeClient();
                        },
                        buttonTwoText: '',
                        buttonTwoFunction: () => null,
                    };
                    break;

                case UNFINISHED:
                    content = {
                        title: t('system.unfinishedTitle'),
                        textContent: t('system.unfinishedContent'),
                        buttonOneText: t('system.resume'),
                        buttonOneFunction: () => startGame(),
                        buttonTwoText: '',
                        buttonTwoFunction: () => null,
                    };
                    break;

                case ERROR:
                    content = {
                        title: t('system.generalErrorTitle'),
                        textContent: errorMessage,
                        buttonOneText: t('system.close'),
                        buttonOneFunction: () => closeModal(),
                        buttonTwoText: '',
                        buttonTwoFunction: () => null,
                    };
                    break;
            }
            return content;
        }
    ;

    useEffect(() => {
        setModalContent(setContent(modalType));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalType, currentError]);

    return (
        <div
            className={clsx(styles.modalWrapper)}
            ref={modalWrapperRef}
        >
            <div
                className={clsx(styles.flexWrapper)}
            >
                <div
                    key="modalInnerWrapper"
                    className={clsx(styles.innerWrapper)}
                    ref={modalRef}
                >
                    <p className={styles.modalTitle}>{modalContent?.title}</p>
                    <p className={styles.modalText}>{modalContent?.textContent}</p>
                    {(modalContent?.buttonOneText || modalContent?.buttonTwoText) &&
                        <div className={styles.buttonsWrapper}>

                            {modalContent?.buttonOneText && (
                                <Button
                                    onClick={(): void => {
                                        SoundManager.instance.playVO('ui_accept');
                                        modalContent?.buttonOneFunction();
                                    }}
                                >
                                    <span className={styles.buttonOneText}>{modalContent?.buttonOneText}</span>
                                </Button>
                            )}

                            {modalContent?.buttonTwoText && (
                                <Button
                                    isSecondary
                                    onClick={(): void => {
                                        SoundManager.instance.playVO('ui_accept');
                                        modalContent?.buttonTwoFunction();
                                    }}
                                >
                                    {modalContent?.buttonTwoText}
                                </Button>
                            )}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default SystemPopUp;
