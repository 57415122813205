import React, {useContext, useEffect, useRef} from 'react';
import gsap from 'gsap';
import styles from './Ball.module.scss'
import {GameContext} from "../../../../contexts/GameContext";
import Utils from "../../../../utils/Utils";
import useAnimation from "../../../../hooks/useAllBounceCompleteAnimation";
import {debounce} from "lodash";
import SoundManager from "../../../../utils/SoundManager";

interface BallProps {
    targetX: number;
    targetY: number;
    getCellPosition: (number: number) => { targetX: number, targetY: number };
    unbouncedBallsRef: React.MutableRefObject<HTMLDivElement[]>;
    delay: number;
    number: number;
    onBounceComplete: () => void;
    hasBounced?: boolean;
}

const Ball = ({targetX, targetY, getCellPosition, unbouncedBallsRef, number, delay, onBounceComplete}: BallProps) => {
    const ballRef = useRef<HTMLDivElement | null>(null);
    const timelineRef = useRef<gsap.core.Timeline | null>(null);
    const {
        isResultsShown,
        picked,
        winningDraw,
        speedMultiplier,
        canChangeTotalRowWidth,
        setCanChangeTotalRowWidth,
        goldNumber,
        isDrawSkipped,
    } = useContext(GameContext);

    const isWinningBall = picked.includes(number) && winningDraw.includes(number);
    const isGoldenBall = number === goldNumber;
    const ballSize = Utils.isTablet() || Utils.isDesktop() ? 39 : Utils.isDesktopLarge() ? 60 : 32;
    const resultsShown = useRef(false);

    const {allBounceComplete} = useAnimation({
        picked,
        winningDraw,
        number,
        ballRef,
        isResultsShown,
        canChangeTotalRowWidth,
        setCanChangeTotalRowWidth,
    });

    useEffect(() => {
        const ball = ballRef.current;
        const board = document.getElementById('board');
        if (!ball || !board || isResultsShown) return;
        const boardRect = board?.getBoundingClientRect();
        let boardCenterX;
        if (Utils.isTablet() || Utils.isDesktop()) {
            boardCenterX = boardRect ? boardRect.left + boardRect.width / 2 - 20 : 0;
        } else {
            boardCenterX = boardRect ? boardRect.left + boardRect.width / 2 - 15 : 0;
        }

        const adjustedTargetX = targetX - (ballSize / 2);
        const adjustedTargetY = targetY - (ballSize / 2);
        const bottomPosition = window.innerHeight - ballSize - 15;
        const isDoubleBounce = Math.random() < 0.25;

        const timeline = gsap.timeline();

        timeline.fromTo(ball,
            {y: '-100px', x: boardCenterX, opacity: 1, width: `${ballSize}px`, height: `${ballSize}px`, scale: 1.3},
            {
                y: bottomPosition,
                opacity: 1,
                duration: 2.8,
                ease: 'power3.in',
                yoyo: true,
                delay,
                onComplete: () => {
                    SoundManager.instance.playVO('ball');
                },
            }
        )

        if (isDoubleBounce) {
            const bouncePeak = adjustedTargetY - Math.random() * 0.5 * (adjustedTargetY - bottomPosition);
            const midpointX = (boardCenterX + adjustedTargetX) / 2;
            const bouncePeakX = midpointX + (Math.random() - 0.5) * 0.1 * (adjustedTargetX - boardCenterX);

            timeline.to(ball, {
                y: bouncePeak,
                duration: 0.5,
                ease: 'sine.out',
            });

            timeline.to(ball, {
                x: bouncePeakX,
                duration: 0.5,
                ease: 'linear',
            }, '-=0.5');

            timeline.to(ball, {
                y: bottomPosition,
                duration: 0.5,
                ease: 'sine.in',
                onComplete: () => {
                    SoundManager.instance.playVO('ball');
                },
            });
        }

        timeline.to(ball, {
            y: adjustedTargetY,
            duration: 0.5,
            ease: 'sine.out',
        }).to(ball, {
                x: adjustedTargetX,
                duration: 0.5,
                ease: 'linear',
                onComplete: () => {
                    onBounceComplete();
                    if (!isWinningBall && !isGoldenBall) {
                        SoundManager.instance.playVO('empty_point');
                    }
                    if (isWinningBall && !isGoldenBall) {
                        SoundManager.instance.playVO('win_point');
                    }
                    if (isGoldenBall) {
                        SoundManager.instance.playVO('golden_point');
                    }
                },
                scale: 1,
                opacity: 0.8,
            },
            '-=0.5'
        );

        timelineRef.current = timeline;

        return () => {
            timeline.kill();
            resultsShown.current = false;
        };
    }, []);

    useEffect(() => {
        const handleResize = debounce(() => {
            setTimeout(() => {
                const {targetX, targetY} = getCellPosition(number);

                const ball = ballRef.current;
                const board = document.getElementById('board');
                if (!ball || !board || resultsShown.current) return;
                const boardRect = board.getBoundingClientRect();
                const boardCenterX = Utils.isTablet() || Utils.isDesktop()
                    ? boardRect.left + boardRect.width / 2 - 20
                    : boardRect.left + boardRect.width / 2 - 15;

                const newBallSize = Utils.isTablet() || Utils.isDesktop()
                    ? 39
                    : Utils.isDesktopLarge()
                        ? 60
                        : 32;
                const adjustedTargetX = targetX - (newBallSize / 2);
                const adjustedTargetY = targetY - (newBallSize / 2);

                gsap.killTweensOf(ball);
                if (timelineRef.current) {
                    timelineRef.current.kill();
                }

                const ballRect = ball.getBoundingClientRect();
                const currentX = ballRect.left;
                const currentY = ballRect.top;

                const bottomPosition = window.innerHeight - newBallSize - 15;
                const isDoubleBounce = Math.random() < 0.25;

                const timeline = gsap.timeline();
                timelineRef.current = timeline;

                if (currentY > -15) {
                    gsap.fromTo(ball,
                        {
                            x: currentX,
                            y: currentY,
                            opacity: 1,
                            scale: 1,
                        },
                        {
                            x: adjustedTargetX,
                            y: adjustedTargetY,
                            width: newBallSize,
                            height: newBallSize,
                            opacity: 0.8,
                            scale: 1,
                            duration: 0.8,
                            ease: 'linear',
                            onComplete: () => {
                                onBounceComplete();
                                if (unbouncedBallsRef.current.includes(ball)) {
                                    unbouncedBallsRef.current = unbouncedBallsRef.current.filter((b) => b !== ball);
                                }
                            },
                        });
                } else {
                    if (!unbouncedBallsRef.current.includes(ball)) {
                        unbouncedBallsRef.current.push(ball);
                    }

                    const newDelay = unbouncedBallsRef.current.indexOf(ball) * 1.4;

                    timeline.fromTo(ball,
                        {
                            y: '-100px',
                            x: boardCenterX,
                            opacity: 1,
                            width: `${newBallSize}px`,
                            height: `${newBallSize}px`,
                            scale: 1.3,
                        },
                        {
                            y: bottomPosition,
                            opacity: 1,
                            duration: 2.8,
                            ease: 'power1.in',
                            yoyo: true,
                            delay: newDelay,
                            onComplete: () => {
                                SoundManager.instance.playVO('ball');
                            },
                        }
                    )

                    if (isDoubleBounce) {
                        const bouncePeak = adjustedTargetY - Math.random() * 0.5 * (adjustedTargetY - bottomPosition);
                        const midpointX = (boardCenterX + adjustedTargetX) / 2;
                        const bouncePeakX = midpointX + (Math.random() - 0.5) * 0.1 * (adjustedTargetX - boardCenterX);

                        timeline.to(ball, {
                            y: bouncePeak,
                            duration: 0.5,
                            ease: 'sine.out',
                        });

                        timeline.to(ball, {
                            x: bouncePeakX,
                            duration: 0.5,
                            ease: 'linear',
                        }, '-=0.5');

                        timeline.to(ball, {
                            y: bottomPosition,
                            duration: 0.5,
                            ease: 'sine.in',
                            onComplete: () => {
                                SoundManager.instance.playVO('ball');
                            },
                        });
                    }

                    timeline.to(ball, {
                        y: adjustedTargetY,
                        duration: 0.5,
                        ease: 'sine.out',
                    }).to(ball, {
                            x: adjustedTargetX,
                            width: newBallSize,
                            height: newBallSize,
                            opacity: 0.8,
                            scale: 1,
                            duration: 0.5,
                            ease: 'linear',
                            onComplete: () => {
                                onBounceComplete();
                                if (!isWinningBall && !isGoldenBall) {
                                    SoundManager.instance.playVO('empty_point');
                                }
                                if (isWinningBall && !isGoldenBall) {
                                    SoundManager.instance.playVO('win_point');
                                }
                                if (isGoldenBall) {
                                    SoundManager.instance.playVO('golden_point');
                                }
                            },
                        },
                        '-=0.5'
                    );
                }
            }, 50);
        }, 120);

        window.addEventListener('resize', handleResize);
        return () => {
            timelineRef.current = null;
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isResultsShown) {
            resultsShown.current = true;
            allBounceComplete();
        }
    }, [isResultsShown]);

    useEffect(() => {
        if (timelineRef.current) {
            timelineRef.current.timeScale(speedMultiplier);
        }
    }, [speedMultiplier]);

    useEffect(() => {
        if (isDrawSkipped) {
            const ball = ballRef.current;
            const board = document.getElementById('board');
            if (!ball || !board || isResultsShown) return;

            if (timelineRef.current) {
                timelineRef.current.kill();
            }

            const adjustedTargetX = targetX - (ballSize / 2);
            const adjustedTargetY = targetY - (ballSize / 2);

            gsap.set(ball, {
                x: adjustedTargetX,
                y: adjustedTargetY,
                opacity: 0,
                width: `${ballSize}px`,
                height: `${ballSize}px`,
                scale: 1,
            });

            const timeline = gsap.timeline();
            timeline.to(ball, {
                opacity: 1,
                duration: 1,
                ease: 'power1.inOut',
                onComplete: () => {
                    onBounceComplete();
                },
            });

            timelineRef.current = timeline;
        }
        return () => {
            if (timelineRef.current) {
                timelineRef.current.kill();
                timelineRef.current = null;
            }
        }
    }, [isDrawSkipped])

    return (
        <div
            ref={ballRef}
            className={styles.ball}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                position: 'absolute',
                top: 0,
                left: 0,
                color: 'black',
                fontFamily: 'Kanit',
                fontSize: '16px',
                fontWeight: 'bold',
            }}
        >
            {isResultsShown && isWinningBall && number}
        </div>

    );
};

export default Ball;
