export const en = {
    translation: {
        soundButton: {
            soundOff: 'Sound off',
            soundOn: 'Sound on',
        },
        menu: {
            play: 'PLAY',
            tryDemo: 'TRY A DEMO ROUND',
            topScores: 'TOP SCORES',
            prizes: 'PRIZE PLAN',
            howItWorks: 'HOW TO PLAY!',
            achievements: 'ACHIEVEMENTS',
            statistics: 'STATISTICS',
        },
        multiplier: {
            spinWheel: 'Spin wheel',
            skip: 'Skip!',
            multiplier: 'Multiplier x {{number}}',
            winningChance: 'You have a chance of winning up to {{times}} times more!',
        },
        actionBoard: {
            info: 'Choose your stake, Multiplier and Golden number',
            payButtonText: 'Bet {{amount}}',
            topPrize: 'Top prize',
            multiplier: {
                label: 'Double your stake to try multiplier.',
                subHeading: 'You have a chance of winning up to 10 times more!',
            },
            goldenNumber: {
                buttonName: 'Golden number',
                label: 'Choose one number as the Golden number to win bonus prize.',
            },
            skipDrawing: 'Skip drawing',
        },
        roundStart: {
            infoheader1: '1. Select your numbers',
            infoparagraph1: 'Choose up to 11 numbers.',
            infoheader2: '2. Select your extras',
            infoparagraph2: 'Multiplier can give you up to 10 times bigger prize. Golden number lets you win according to a beefier prize plan.',
            infoheader3: '3. Select stake & play!',
            infoparagraph3: 'Go ahead! Pick your lucky numbers and see if fortune favours you!',
        },
        roundEnd: {
            playAgain: 'Bet {{price}}',
            backToStart: 'Choose new numbers',
        },
        randomizer: {
            randomizeNumbers: 'Randomize',
            clearNumbers: 'Clear',
        },
        start: {
            heading: 'Welcome',
            subHeading: 'Time to test your sport quiz skills - and your luck to win cash!',
            playButton: 'PLAY',
            seeTopScoreButton: 'See top scores',
            seePrizesButton: 'See prizes',
        },
        header: {
            gameInfo: 'Game info',
            prizePlans: 'Prize plans',
            prize: 'Prize',
            demo: 'Demo',
            chooseNumbers: 'Choose your numbers!',
            chooseGoldenNumber: 'Choose your <gold>golden</gold> number!',
            activeMultiplier: 'Multiplier active',
            multiplier: 'Multiplier',
            multiplierSubHeading: 'You have a chance of winning up to 10 times more!',
            goldenNumber: 'Golden number',
            gameName: 'Keno',
            numbersRight: 'You got {{rightNumbers}} Numbers right!',
            noPrize: 'Unfortunately, there’s no prize for only {{rightNumbers}} correct numbers.',
            win: 'You won {{prize}} for matching {{rightNumbers}} numbers.',
        },
        info: {
            header: 'How to play',
            firstParagraph: '20 random numbers will be drawn. Will you choose the winning numbers?',
            secondParagraph: 'Choose a set of numbers, either on your own or by clicking the “randomize numbers” button and see if you win. Decide how much you want to wager - higher wager means higher potential win! Boost any potential win by adding multiplier and a golden number. The multiplier will give you a chance to multiply any potential win 1x, 3x, 5x or a whooping 10x! Why not also pick a golden number to get a chance to further increase any potential win!',
            thirdParagraph: 'Start the game and see how many of the 20 numbers to be drawn that will match your chosen numbers.',
            forthParagraph: 'Go ahead! Pick your lucky numbers and see if fortune favours you!',
            buttonOne: 'Prize plan',
            buttonTwo: 'Play',
        },
        prizes: {
            header: 'Prize plan',
            stake: '{{stake}} stake',
            prizeValue: 'Prize value:',
            hits: 'Hits',
            kenoPrize: 'Keno prize',
            specialPrize: 'Keno with golden number prize',
            buttonOne: 'Game info',
            buttonTwo: 'Play',
        },
        topscore: {
            today: 'Today',
            week: 'Week',
            leaderboard: 'Leaderboard',
            quizWinnersLabel: 'Previous winners',
            competitionEnd: 'competition ends:',
            seePrizes: 'See prizes',
            quizWinners: {
                tableHeaderDate: 'Date',
                tableHeaderWinner: 'Winner',
                tableHeaderScore: 'Score',
                weekNumber: 'Week {{weekNumber}}',
                noWinner: 'No winner',
            },
        },
        prizeplan: {
            title: 'Prize plan',
            text: 'The lottery consists of 1 000 000 lottery tickets per stake each with a total of 1 000 000 winning tickets.',
            heading1: 'Quantity',
            heading2: 'Prize',
            jackpot: 'Million chance',
            returnToPlayer: 'Return to player',
            payoutPercentage: '%',
            showGemValues: 'Show gem values',
            million: 'Million chance',
        },
        activeGame: {
            skipButton: 'skip',
        },
        system: {
            login: 'Sign in',
            logout: 'Logout',
            demo: 'Demo',
            loginTitle: 'Sign in',
            loginContent:
                'You need to sign in to be able to play and win.',
            walletTitle: "Not enough funds to play!",
            walletContent: 'Please deposit funds to your account.',
            sessionTimeoutTitle: 'Your session has timed out',
            sessionTimeoutContent: 'Please logon again to continue playing. If you were in the middle of playing a game, your game can be played once you have logged on again.',
            sessionTimeoutButton: 'Exit',
            unfinishedTitle: 'Resume your game?',
            unfinishedContent:
                'You have an unfinished ticket. Do you want to play it now?',
            generalErrorTitle: 'An error occurred',
            generalErrorContent: 'Something went wrong. Try again.',
            close: 'Close',
            resume: 'Play',
            viewResult: 'Check result',
            ok: 'Ok',
        },
        paymentSuccess: 'Payment successful!',
        amount: '{{amount}}',
        ok: 'Ok',
        currencyFormat: 'EUR',
        currencyLocale: 'en-GB',
        errors: {
            default: 'Something went wrong!',
            userBalance: 'Could not fetch balance, reload the page to try again.',
            purchase: 'A problem occurred during purchase, try again.',
            gameStop: 'You have game stop activated.',
            limitNotSet:
                'You have to set your game limits before purchasing tickets.',
            noAvailableTickets: 'There are no available tickets to purchase.',
            getTicketError: 'Something went wrong when collecting your ticket.',
            somethingWrongWithTicket: 'Unknown error with your ticket.',
            replayLoginError: 'You must login to replay your ticket.',
        },
        doNotSupportLandscape: 'Rotate device to play',
    },
};
