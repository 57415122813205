import React, {Fragment, useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import {motion} from 'framer-motion';
import {useTranslation, Trans} from 'react-i18next';
import Wallet from './components/Wallet/Wallet';
import HamburgerMenu from "./components/HamburgerMenu/HamburgerMenu";
import {GAME, INFO, MULTIPLIER, ROUND_END, START, STATS} from "../../config/constants";
import {AppContext} from "../../contexts/AppContext";
import {GameContext} from "../../contexts/GameContext";
// import SoundManager from '../../utils/SoundManager';
//assets
import Logo from '../../assets/logo/logo.png'
import SoundIcon from '../../assets/svg/sound.svg';
import pipeContainer from '../../assets/elements/pipe-container.png'
import styles from './TopMenu.module.scss';
import Countdown from "../Countdown/Countdown";
import SoundOffIcon from "../../assets/svg/sound-off.svg";
import cloud1 from '../../assets/background/cloud1.png'
import cloud2 from '../../assets/background/cloud2.png'
import cloud3 from '../../assets/background/cloud3.png'
import useCloudAnimation from "../../hooks/useCloudAnimation";
import Utils from "../../utils/Utils";
import ProgressTable from "./components/ProgressTable/ProgressTable";
import SoundManager from "../../utils/SoundManager";

const fadeVariant = {
    hidden: {opacity: 0},
    visible: {opacity: 1},
    exit: {opacity: 0},
};

interface Props {
    displayBalance: boolean;
    displaySoundButton: boolean;
    displayCloseButton: boolean;
    picked: number[];
}

function TopMenu({displayBalance, displaySoundButton, displayCloseButton, picked}: Props): JSX.Element {
    useCloudAnimation();
    const {callback} = useContext(AppContext)
    const {t} = useTranslation();
    const {
        gameState,
        winningDraw,
        isResultsShown,
        showWinningDraw,
        prizePlans,
        isDemo,
        demoTicket,
        showSkipDrawing,
        currentTicket,
        isSelectingGoldNumber,
        multiplier,
    } = useContext(GameContext);

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [isOpen, setIsOpen] = useState(false);
    const [isMuted, setIsMuted] = useState(() => SoundManager.instance.isAppMuted());
    const [pipePosition, setPipePosition] = useState({top: -290, left: 0});
    const [prize, setPrize] = useState<number | null>(null);

    const isTablet = viewportWidth >= 768;

    const closeGame: () => void = () => {
        callback('close', '');
        SoundManager.instance.playVO('burger_menu_click');
    };

    const item = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1,
        }
    };

    const onClickSound = (value: boolean) => {
        setIsMuted(value);
        SoundManager.instance.muteApp(value);
        callback('audioToggle', value);
        if (!value) {
            SoundManager.instance.playVO('burger_menu_click');
        }
    };

    const checkGameState = () => {
        if (Utils.isDesktopLarge()) {
            return 'var(--topbar-height-desktop)';
        } else if (Utils.isDesktop()) {
            return 'var(--topbar-height-desktop-small)';
        }

        switch (gameState) {
            case GAME:
                return isTablet ? 'var(--topbar-height-mobile-game)' : 'var(--topbar-height-mobile-game)';
            case ROUND_END:
                return isTablet ? 'var(--topbar-height-mobile-game)' : 'var(--topbar-height-mobile-game)';
            case MULTIPLIER:
                return isTablet ? 'var(--topbar-height-tablet-start)' : 'var(--topbar-height-mobile-game)';
            case INFO:
                return isTablet ? 'var(--topbar-height-tablet-start)' : 'var(--topbar-height-mobile-info)';
            case START:
                return isTablet ? 'var(--topbar-height-tablet-start)' : 'var(--topbar-height-mobile-start)';
            default:
                return isTablet ? 'var(--topbar-height-tablet-start)' : 'var(--topbar-height-mobile-info)';
        }
    }

    const processWinningBalls = () => {
        return winningDraw.filter((num) => picked.includes(num));
    };

    const handleCountdownComplete = () => {
        showWinningDraw();
    }

    useEffect(() => {
        if (isDemo) {
            setPrize(demoTicket.prizeValue)
        } else {
            let plan = prizePlans.find(plan =>
                plan.chosenNumbersCount === picked.length &&
                !plan.isSpecialPrize,
            );
            if (plan) {
                setPrize(plan.prizes[processWinningBalls().length].prizeFactor);
            }
        }
    }, [picked.length, processWinningBalls]);

    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                SoundManager.instance.stopVO();
                SoundManager.instance.playVO('main_ambience');
            } else {
                SoundManager.instance.stopVO();
            }
        };

        if (document.visibilityState === 'visible') {
            SoundManager.instance.stopVO();
            SoundManager.instance.playVO('main_ambience');
        }
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            SoundManager.instance.stopVO();
        };
    }, []);

    useEffect(() => {
        const updatePipePosition = () => {
            const board = document.getElementById('board');
            if (board) {
                const boardRect = board.getBoundingClientRect();
                const centerX = boardRect.left + boardRect.width / 2;

                const topValue = Utils.isMobile() ? -290 : -320;
                const centerPoint = Utils.isMobile() ? 25 : 30;

                const wrapperWidth = 1420;
                const viewportWidth = window.innerWidth;

                const horizontalOffset =
                    viewportWidth > wrapperWidth + 20
                        ? (viewportWidth - wrapperWidth) / 2
                        : 0;

                const adjustedCenterX = centerX - horizontalOffset;

                setPipePosition({
                    top: topValue,
                    left: adjustedCenterX - centerPoint,
                });
            }
        };

        updatePipePosition();

        window.addEventListener('resize', updatePipePosition);
        return () => window.removeEventListener('resize', updatePipePosition);
    }, []);


    return (
        <motion.div
            className={clsx(styles.wrapper, {
                [styles[gameState]]: gameState,
            })}
            key="top-menu"
            animate={{
                height: checkGameState(),
                transition: {duration: gameState ? 0.4 : 0.2, ease: 'easeInOut'},
            }}
        >
            <img className={clsx(styles.cloud, 'cloud')} src={cloud1} alt={'cloud'}/>
            <img className={clsx(styles.cloud, 'cloud')} src={cloud2} alt={'cloud'}/>
            <img className={clsx(styles.cloud, 'cloud')} src={cloud3} alt={'cloud'}/>

            {isDemo && (
                <motion.div
                    key="demo"
                    className={styles.demoTag}
                    variants={fadeVariant}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    transition={{duration: 0.3}}
                >
                    {t('header.demo')}
                </motion.div>
            )}

            <div className={styles.topInnerWrapper}>
                {gameState === GAME && !isResultsShown && showSkipDrawing && (
                    <motion.div
                        className={clsx(styles.progressTable, {
                            [styles.hasMultiplier]: currentTicket && currentTicket.boughtMultiplier && multiplier && multiplier > 1,
                        })}
                        key="progress"
                        variants={fadeVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{duration: 0.3}}
                    >
                        <ProgressTable/>
                    </motion.div>
                )}

                {gameState === GAME || gameState === ROUND_END ? (
                    <motion.button
                        key="mute-button"
                        className={clsx(styles.menuButton, {[styles.hideButton]: !displaySoundButton})}
                        variants={item}
                        initial="hidden"
                        animate="visible"
                        onClick={() => onClickSound(!isMuted)}
                        onMouseEnter={() => {
                            SoundManager.instance.playVO('button_hover');
                        }}
                    >
                        <Fragment key={`${isMuted}`}>
                            {isMuted ? (
                                <img
                                    src={SoundOffIcon}
                                    className={styles.menuIcon}
                                    alt=""
                                />
                            ) : (
                                <img
                                    src={SoundIcon}
                                    className={clsx(styles.menuIcon, styles.isMuted)}
                                    alt=""
                                />
                            )}
                        </Fragment>
                    </motion.button>
                ) : (
                    <HamburgerMenu
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        displaySoundButton={displaySoundButton}
                        displayCloseButton={displayCloseButton}
                        isMuted={isMuted}
                        setIsMuted={setIsMuted}
                        closeGame={closeGame}
                    />
                )}
                <img
                    src={Logo}
                    className={styles.logo}
                    alt={'KENO'}
                />
                {gameState === GAME && (
                    <motion.img
                        id={'pipe'}
                        key={'pipe'}
                        src={pipeContainer}
                        alt="Asset"
                        initial={{y: '-100%', opacity: 0}}
                        animate={{y: 0, opacity: 1}}
                        exit={{y: '-100%', opacity: 0}}
                        transition={{duration: 2, ease: 'easeInOut'}}
                        style={{
                            position: 'absolute',
                            top: `${pipePosition.top}px`,
                            left: `${pipePosition.left}px`,
                            width: Utils.isMobile() ? '50px' : '60px',
                            zIndex: 200,
                        }}
                    />
                )}
                <Wallet displayBalance={displayBalance}/>
            </div>

            <div className={clsx(styles.startHeader, {
                [styles.isGame]: gameState === GAME || gameState === ROUND_END,
                [styles.isMultiplier]: gameState === MULTIPLIER,
                [styles.isStart]: gameState === START,
                [styles.isInfo]: gameState === INFO,
            })}>
                {gameState === INFO && (
                    <motion.h1
                        key="info"
                        variants={fadeVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{duration: 0.3}}
                    >
                        {t('header.gameInfo')}
                    </motion.h1>
                )}
                {gameState === STATS && (
                    <motion.h1
                        key="info"
                        variants={fadeVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{duration: 0.3}}
                    >
                        {t('header.prizePlans')}
                    </motion.h1>
                )}
                {gameState === START && (
                    <motion.h1
                        key="chooseNumbers"
                        variants={fadeVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{duration: 0.3}}
                    >
                        {isSelectingGoldNumber ? (
                            <Trans
                                i18nKey="header.chooseGoldenNumber"
                                components={{
                                    gold: <span style={{ color: '#EEDEAD' }} />,
                                }}
                            />
                        ) : (
                            <Trans i18nKey="header.chooseNumbers" />
                        )}
                    </motion.h1>
                )}
                {gameState === MULTIPLIER && (
                    <motion.div
                        key="multiplierHeaderBox"
                        className={styles.multiplierHeaderBox}
                        variants={fadeVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{duration: 0.3}}
                    >
                        <h1>{t('header.multiplier')}</h1>
                        {multiplier === null &&
                            <p className={styles.multiplierText}>{t('header.multiplierSubHeading')}</p>
                        }
                    </motion.div>
                )}
                {gameState === GAME && (
                    <motion.div
                        key="countdown"
                        variants={fadeVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{duration: 0.3}}
                    >
                        <Countdown duration={3} onComplete={handleCountdownComplete}/>
                    </motion.div>
                )}
                {(gameState === GAME || gameState === ROUND_END) && isResultsShown && (
                    <motion.div
                        key="gameHeaderBox"
                        className={styles.gameHeaderBox}
                        variants={fadeVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{duration: 0.3}}
                    >
                        {prize === null || prize === 0 ? (
                            <>
                                <h1>{t('header.numbersRight', {rightNumbers: processWinningBalls().length})}</h1>
                                <p className={styles.noWinText}>{t('header.noPrize', {rightNumbers: processWinningBalls().length})}</p>
                            </>
                        ) : (
                            <>
                                <h1>{t('header.numbersRight', {rightNumbers: processWinningBalls().length})}</h1>
                                <p className={styles.winText}>{t('header.win', {
                                    prize: Utils.formatCurrency(prize),
                                    rightNumbers: processWinningBalls().length
                                })}</p>
                            </>
                        )}
                    </motion.div>
                )}
            </div>
        </motion.div>
    );
}

export default TopMenu;
