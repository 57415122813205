import {Howl} from 'howler';

interface SoundMap {
    [key: string]: Howl;
}

export default class SoundManager {
    private static _instance: SoundManager | null = null;
    private isMuted: boolean = false;
    private voSprites: SoundMap = {};
    public currentSound: string | null = null;

    constructor() {
        if (SoundManager._instance) {
            return SoundManager._instance;
        }

        const soundNames = [
            'main_ambience', 'ui_accept',
            'wheel_spin', 'wheel_popup', 'wheel_win',
            'payment_transaction', 'confetti',
            'randomizer_slide', 'randomizer_select',
            'burger_menu_click', 'burger_menu_slide',
            'ball', 'popup_results', 'skip_toggle',
            'bet_start', 'betmenu_popup', 'betmenu_select',
            'empty_point', 'win_point', 'golden_point',
            'feature_on_off', 'button_hover',
            'countdown'
        ];

        soundNames.forEach((soundName) => {
            let volume = 0.6;
            if (soundName === 'main_ambience') {
                volume = 0.4;
            }
            this.voSprites[soundName] = new Howl({
                src: [`/sounds/${soundName}.mp3`],
                volume: volume,
                loop: soundName === 'main_ambience',
            });
        });

        return this;
    }

    public static get instance(): SoundManager {
        if (!SoundManager._instance) {
            SoundManager._instance = new SoundManager();
        }

        return SoundManager._instance;
    }

    public playVO(id: string) {
        const sound = this.voSprites[id];
        if (sound && !this.isMuted) {
            sound.play();
            this.currentSound = id;
        }
    }

    public stopVO() {
        Object.values(this.voSprites).forEach((sound) => sound.stop());
    }

    public muteApp(value: boolean) {
        Object.values(this.voSprites).forEach((sound) => {
            if (value) {
                sound.mute(true);
            } else {
                sound.mute(false);
            }
        });
        this.isMuted = value;
    }

    public isAppMuted(): boolean {
        return this.isMuted;
    }
}
