import React, {Fragment, useContext, useEffect, useRef} from 'react';
import clsx from "clsx";
import {motion} from 'framer-motion';
import styles from './HamburgerMenu.module.scss';
import MinimizeMenuIcon from '../../../../assets/svg/menu-minimize.svg';
import SoundIcon from '../../../../assets/svg/sound.svg';
import SoundOffIcon from '../../../../assets/svg/sound-off.svg';
import CloseIcon from '../../../../assets/svg/close.svg';
import InfoIcon from '../../../../assets/svg/info.svg';
import StatsIcon from '../../../../assets/svg/stats.svg';
import HomeIcon from '../../../../assets/svg/home.svg';
import MenuIcon from "../../../../assets/svg/menu.svg";
import {AppContext} from "../../../../contexts/AppContext";
import {GameContext} from "../../../../contexts/GameContext";
import {INFO, START, STATS} from "../../../../config/constants";
import SoundManager from "../../../../utils/SoundManager";

interface HamburgerMenuProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    displaySoundButton: boolean;
    displayCloseButton: boolean;
    isMuted: boolean;
    setIsMuted: (isMuted: boolean) => void;
    closeGame: () => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({
                                                         isOpen,
                                                         setIsOpen,
                                                         displaySoundButton,
                                                         displayCloseButton,
                                                         isMuted,
                                                         setIsMuted,
                                                         closeGame
                                                     }) => {
    const {callback} = useContext(AppContext);
    const {setGameState} = useContext(GameContext);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const item = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1,
        }
    };

    const handleMenuToggle: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.stopPropagation();
        setIsOpen(!isOpen);
        if (!isOpen) {
            SoundManager.instance.playVO('burger_menu_slide');
        }
    };

    const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    const onClickHome: () => void = () => {
        setGameState(START);
        closeMenu();
    }

    const onClickSound = (value: boolean, event: React.MouseEvent<HTMLButtonElement>)  => {
        event.stopPropagation();
        setIsMuted(value);
        SoundManager.instance.muteApp(value);
        callback('audioToggle', value);
        if (!value) {
            SoundManager.instance.playVO('burger_menu_click');
        }
    };

    const onClickInfo: () => void = () => {
        setGameState(INFO);
        closeMenu();
    }

    const onClickStats: () => void = () => {
        setGameState(STATS);
        closeMenu();
    }

    const closeMenu: () => void = () => {
        setIsOpen(false);
        SoundManager.instance.playVO('burger_menu_click');
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleOutsideClick);
            document.addEventListener('touchstart', handleOutsideClick, { passive: true });
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
        };
    }, [isOpen]);

    return (
        <motion.div
            key='menu'
            className={styles.menu}
            ref={containerRef}
            animate={{
                height: isOpen ? '' : '40px',
                background: isOpen ? 'linear-gradient(180deg, #563642 0%, #AE374C 70%, #DAA691 100%)' : 'rgba(0,0,0,0)',
                transition: {
                    delayChildren: 0.3,
                    staggerChildren: 0.2
                }
            }}
        >
            {isOpen ? (
                <motion.button
                    className={clsx(styles.menuButton)}
                    key="minimize-button"
                    onClick={handleMenuToggle}
                >
                    <img
                        src={MinimizeMenuIcon}
                        className={clsx(styles.menuIcon)}
                        alt=""
                    />
                </motion.button>
            ) : (
                <motion.button
                    className={clsx(styles.menuButton, styles.mainButton)}
                    onClick={handleMenuToggle}
                >
                    <img
                        src={MenuIcon}
                        className={clsx(styles.menuIcon)}
                        alt=""
                    />
                </motion.button>
            )}

            {isOpen && (
                <>
                    <motion.button
                        key="home-button"
                        className={clsx(styles.menuButton)}
                        variants={item}
                        initial="hidden"
                        animate="visible"
                        onClick={onClickHome}
                        onMouseEnter={() => {
                            SoundManager.instance.playVO('button_hover');
                        }}
                    >
                        <img src={HomeIcon} className={clsx(styles.menuIcon)} alt=""/>
                    </motion.button>
                    <motion.button
                        key="mute-button"
                        className={clsx(styles.menuButton, {[styles.hideButton]: !displaySoundButton})}
                        variants={item}
                        initial="hidden"
                        animate="visible"
                        onClick={(event) => onClickSound(!isMuted, event)}
                        onMouseEnter={() => {
                            SoundManager.instance.playVO('button_hover');
                        }}
                    >
                        <Fragment key={`${isMuted}`}>
                            {isMuted ? (
                                    <img
                                        src={SoundOffIcon}
                                        className={styles.menuIcon}
                                        alt=""
                                    />
                            ) : (
                                <img
                                    src={SoundIcon}
                                    className={clsx(styles.menuIcon, styles.isMuted)}
                                    alt=""
                                />
                            )}
                        </Fragment>
                    </motion.button>
                    <motion.button
                        key="info-button"
                        className={clsx(styles.menuButton)}
                        variants={item}
                        initial="hidden"
                        animate="visible"
                        onClick={onClickInfo}
                        onMouseEnter={() => {
                            SoundManager.instance.playVO('button_hover');
                        }}
                    >
                        <img src={InfoIcon} className={styles.menuIcon} alt=""/>
                    </motion.button>
                    <motion.button
                        key="stats-button"
                        className={clsx(styles.menuButton)}
                        variants={item}
                        initial="hidden"
                        animate="visible"
                        onClick={onClickStats}
                        onMouseEnter={() => {
                            SoundManager.instance.playVO('button_hover');
                        }}
                    >
                        <img src={StatsIcon} className={styles.menuIcon} alt=""/>
                    </motion.button>
                    <motion.button
                        key="close-game-button"
                        className={clsx(styles.menuButton, {[styles.hideButton]: !displayCloseButton})}
                        variants={item}
                        initial="hidden"
                        animate="visible"
                        onClick={closeGame}
                        onMouseEnter={() => {
                            SoundManager.instance.playVO('button_hover');
                        }}
                    >
                        <img src={CloseIcon} className={styles.menuIcon} alt=""/>
                    </motion.button>
                </>
            )}
        </motion.div>
    );
};

export default HamburgerMenu;
