import React, {useContext, useEffect} from "react";
import {ReactComponent as PaymentSuccessIcon} from "../../../../assets/svg/payment-successful.svg";
import styles from "./PaymentSuccess.module.scss";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {GameContext} from "../../../../contexts/GameContext";
import SoundManager from "../../../../utils/SoundManager";

const PaymentSuccessful = () => {
    const {t} = useTranslation();
    const {gameState} = useContext(GameContext);

    useEffect(() => {
        SoundManager.instance.playVO('payment_transaction');
    }, []);

    return (
        <div className={clsx(styles.successContainer, {
            [styles[gameState]]: gameState,
        })}>
            <PaymentSuccessIcon/>
            <p>{t('paymentSuccess')}</p>
        </div>
    );
};

export default PaymentSuccessful;
