import React, {useContext} from 'react';
import Button from "../elements/Button/Button";
import styles from './SkipDrawing.module.scss';
import {useTranslation} from "react-i18next";
import normalSpeedIcon from "../../assets/svg/normal-speed.svg";
import skipDrawingIcon from "../../assets/svg/skip-drawing.svg";
import clsx from "clsx";
import Switch from "../elements/Switch/Switch";
import {GameContext} from "../../contexts/GameContext";

const SkipDrawing = () => {
    const {t} = useTranslation();
    const {setIsDrawSkipped, isDrawSkipped} = useContext(GameContext);

    const handleSkipDrawing = () => {
        console.log('skip drawing');
        setIsDrawSkipped(true);
    }

    return (
        <div className={styles.skipDrawingContainer}>
            <Button
                className={styles.skipDrawingButton}
                isSecondary
                onClick={handleSkipDrawing}
                isDisabled={isDrawSkipped}
            >
                {t('actionBoard.skipDrawing')}
            </Button>

            <div className={styles.toggleContainer}>
                <img src={normalSpeedIcon} className={clsx(styles.skipIcon)} alt=""/>
                <Switch/>
                <img src={skipDrawingIcon} className={clsx(styles.skipIcon)} alt=""/>
            </div>
        </div>
    );
};

export default SkipDrawing;
