import React, {useContext} from 'react';
import styles from './Switch.module.scss';
import {GameContext} from "../../../contexts/GameContext";
import SoundManager from "../../../utils/SoundManager";

const ToggleSwitch: React.FC = () => {
    const {speedMultiplier, setSpeedMultiplier} = useContext(GameContext);

    const handleToggle = () => {
        setSpeedMultiplier(speedMultiplier === 1 ? 2 : 1);
        SoundManager.instance.playVO('skip_toggle');
    };

    return (
        <>
            <label className={styles.switch}>
                <input type="checkbox" onClick={handleToggle}/>
                <span className={styles.slider}/>
            </label>
        </>
    )

};

export default ToggleSwitch;
