import React, {useContext, useState} from 'react';
// @ts-ignore
import Chance from "chance";
import Button from "../../../elements/Button/Button";
import styles from "./Randomizer.module.scss";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {GameContext} from "../../../../contexts/GameContext";
import SoundManager from "../../../../utils/SoundManager";

const chance = new Chance();

const Randomizer: React.FC = () => {
    const [randomizeMenuOpen, setRandomizeMenuOpen] = useState<boolean>(false);
    const {picked, setPicked, isGoldNumberUsed, setGoldNumber, setIsGoldNumberUsed, setIsSelectingGoldNumber} = useContext(GameContext)
    const {t} = useTranslation();

    const handleMenuToggle = () => {
        setRandomizeMenuOpen(!randomizeMenuOpen);
        if (!randomizeMenuOpen) {
            SoundManager.instance.playVO('randomizer_slide');
        }
    }

    const randomize = (value: number) => {
        const randomPick = chance.unique(chance.natural, value, {min: 1, max: 80});
        setPicked(randomPick);
        if (isGoldNumberUsed) {
            setGoldNumber(chance.pickone(randomPick));
            setIsSelectingGoldNumber(false);
        }
        SoundManager.instance.playVO('randomizer_select');
    };

    const clearPicked = () => {
        setPicked([]);
        setIsGoldNumberUsed(false);
        setGoldNumber(0);
        SoundManager.instance.playVO('ui_accept');
    };

    return (
        <div className={styles.randomizerBox}>
            <div className={styles.randomize}>
                <Button isTertiary className={clsx(styles.randomizerToggle, {
                    [styles.active]: randomizeMenuOpen,
                })}
                        onClick={handleMenuToggle}>
                    {t('randomizer.randomizeNumbers')}
                </Button>
                <div className={clsx(styles.randomizeMenuBox, {
                    [styles.isOpen]: randomizeMenuOpen,
                })}>
                    <p className={styles.randomizerTitle}>Select amount</p>
                    <div className={styles.randomizeMenu}>
                        {Array.from(Array(11).keys()).map(i => (
                            <Button
                                className={styles.randomizeButton}
                                isTertiary
                                key={i + 1}
                                onClick={() => {
                                    setRandomizeMenuOpen(false);
                                    randomize(i + 1)
                                }}
                                onMouseEnter={() => {
                                    if (randomizeMenuOpen) {
                                        SoundManager.instance.playVO('button_hover');
                                    }
                                }}>
                                {i + 1}
                            </Button>
                        ))}
                    </div>
                </div>
            </div>
            <Button isTertiary className={clsx(styles.randomizerToggle, {
                [styles.disabled]: picked.length === 0,
            })}
                    isDisabled={picked.length === 0}
                    onClick={() => clearPicked()}
            >
                {t('randomizer.clearNumbers')}
            </Button>
        </div>
    );
};

export default Randomizer;
