import React, {useContext, useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from "framer-motion";
import throttle from 'lodash.throttle';
import {GameContext} from "../../contexts/GameContext";
import WheelPixiApp from './PixiApp/WheelPixiApp';
import Button from '../elements/Button/Button';
import clsx from "clsx";
import gsap from "gsap";
import {GAME} from "../../config/constants";
import styles from './Multiplier.module.scss';
import Utils from "../../utils/Utils";
import {useTranslation} from "react-i18next";
import SoundManager from "../../utils/SoundManager";

const MultiplierWheel = () => {
    const {t} = useTranslation();
    const canvasRef = useRef<HTMLDivElement | null>(null);
    const applicationRef = useRef<WheelPixiApp | null>(null);
    const {
        setGameState,
        currentTicket,
        multipliersArray,
        demoTicket,
        multiplier
    } = useContext(GameContext);
    const [multiplierState, setMultiplierState] = useState<number | null>(null);
    const [isSpinning, setIsSpinning] = useState(false);

    const possibleMultipliers = () => {
        return multipliersArray.map((multiplier) => Utils.formatMultiplier(multiplier));
    };

    const onResize = throttle(() => {
        if (applicationRef.current) {
            applicationRef.current.onResize();
        }
    }, 100);

    const handleSpin = () => {
        setIsSpinning(true);
        if (applicationRef.current) {
            applicationRef.current.spin();
            SoundManager.instance.playVO('wheel_spin');
        }
    };

    const handleSkip = () => {
        setMultiplierState(multiplier);
        setGameState(GAME);
    };

    const handleSpinEnd = () => {
        setIsSpinning(false);
        setMultiplierState(multiplier);
        setTimeout(() => {
            setGameState(GAME);
        }, 2500);
    };

    useEffect(() => {
        window.addEventListener('resize', onResize);

        return () => {
            if (applicationRef.current) {
                window.removeEventListener('resize', onResize);
                applicationRef.current.destroy();
                applicationRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (applicationRef.current) {
            applicationRef.current.onResize();
        }
    }, []);

    useEffect(() => {
        if (!applicationRef.current && canvasRef.current) {
            applicationRef.current = new WheelPixiApp({
                htmlElement: canvasRef.current,
                chosenMultiplier: currentTicket.multiplier || demoTicket.multiplier,
                onSpinEnd: handleSpinEnd,
                multipliersArray: possibleMultipliers(),
            });
        }
        SoundManager.instance.playVO('wheel_popup');
        gsap.fromTo(
            canvasRef.current,
            {opacity: 0, y: -50},
            {opacity: 1, y: 0, duration: 1, ease: 'bounce.out'}
        );
    }, []);

    useEffect(() => {
        console.log('multiplier', multiplier);
    }, [multiplier]);

    return (
        <div className={styles.container}>
            <div className={styles.canvasBox} ref={canvasRef}/>
            <div className={styles.resultBox}>
                {!isSpinning && multiplier === null ? (
                    <AnimatePresence>
                        <motion.div
                            initial={{opacity: 0, scale: 0.9}}
                            animate={{opacity: 1, scale: 1}}
                            exit={{opacity: 0, scale: 0.9}}
                            transition={{duration: 0.3}}
                            className={styles.motionWrapper}
                        >
                            <Button onClick={handleSpin} className={styles.spinButton}>
                                <span className={styles.spinText}>{t('multiplier.spinWheel')}</span>
                            </Button>
                        </motion.div>
                    </AnimatePresence>
                ) : (
                    <div className={clsx(styles.textBox, {
                        [styles.fade]: multiplierState !== null,
                        [styles.visible]: multiplierState !== null,
                        [styles.hidden]: multiplierState === null,
                    })}>
                        <h1 className={styles.result}>
                            {t('multiplier.multiplier', {number: multiplier})}
                        </h1>
                        {multiplier && multiplier > 1 && (
                            <span className={styles.resultText}>
                        {t('multiplier.winningChance', {times: multiplier})}
                    </span>
                        )}
                    </div>)}
                <div className={styles.bottomBlock}>
                    <div className={styles.spinButtonContainer}>
                        <AnimatePresence>
                            {!isSpinning && multiplierState === null && (
                                <motion.div
                                    initial={{ opacity: 0, scale: 0.9 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0.9 }}
                                    transition={{ duration: 0.3 }}
                                    className={styles.motionWrapper}
                                >
                                    <Button onClick={handleSpin} className={styles.spinButton}>
                                        <span className={styles.spinText}>{t('multiplier.spinWheel')}</span>
                                    </Button>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                    <Button
                        isSecondary
                        isDisabled={isSpinning}
                        onClick={handleSkip}
                        className={styles.skipButton}
                    >
                        {t('multiplier.skip')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default MultiplierWheel;
